<script>
import { paymentIntents } from '@/api/pay.js'
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
    }
  },
  created () {
    localStorage.utoken = this.$route.query.token || ''
    this.SET_TOKEN(this.$route.query.token)
    console.log(localStorage.utoken)
    window.Airwallex.init({
      env: process.env.VUE_APP_PAY_ENV, // Setup which Airwallex env('demo' | 'prod') to integrate with
      origin: window.location.origin // Set up your event target to receive the browser events message
    })
  },
  mounted () {
    this.toPay()
  },
  methods: {
    ...mapMutations('service', ['SET_TOKEN']),
    toPay () {
      paymentIntents({
        channel: 6,
        goods: [
          {
            goodsId: Number(this.$route.query.goodsId),
            number: 1
          }
        ]
      })
        .then(res => {
          if (res.code === 200) {
            window.Airwallex.redirectToCheckout({
              env: process.env.VUE_APP_PAY_ENV, // Which env('staging' | 'demo' | 'prod') you would like to integrate with
              intent_id: res.data.data.id,
              client_secret: res.data.data.client_secret,
              currency: res.data.data.currency,
              logoUrl: '',
              successUrl: 'https://h5.bifrost.com/pay/subscribe/success?issuccess=1',
              cancelUrl: 'https://h5.bifrost.com/pay/subscribe/success?issuccess=2',
              failUrl: 'https://h5.bifrost.com/pay/subscribe/success?issuccess=2',
              country_code: res.data.CountryCode,
              googlePayRequestOptions: {
                countryCode: 'SG'
              }
            })
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .airwallex {
    width: 100%;
    min-height: 100vh;
    background: #000000;
    .pay-desc{
      width: 642px;
      margin: 0 auto;
      margin-top: 60px;
      margin-top: 60px;
      text-align: center;
      font-size: 23px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      p{
        font-size: 23px;
        opacity: 0.3;
      }
    }
    .card-shu {
      margin: 0 auto;
      margin-top: 60px;
      width: 642px;
      display: flex;
      flex-wrap: wrap;
      .card-list {
        width: 309px;
        position: relative;
        height: 250px;
        background: rgba(38, 38, 38, 0.39);
        border-radius: 10px;
        margin-top: 50px;
        .card-list-decs {
          font-size: 38px;
          text-align: center;
          font-family: Roboto;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          padding-top: 50px;
        }
        .card-list-btn {
          text-align: center;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 94px;
          line-height: 94px;
          background: linear-gradient(147deg, #ffd341 0%, #ffbb1d 100%);
          border-radius: 0px 0px 10px 10px;
          font-size: 33px;
          font-weight: bold;
          color: #000000;
        }
      }
      .card-list:nth-child(even) {
        margin-left: 20px;
      }
    }
    .card-heng-num {
      padding-left: 123px;
      font-size: 38px;
      font-family: Arial Black;
      font-weight: 400;
      color: #ffffff;
    }
    .card-heng-danw {
      padding-left: 23px;
      font-size: 27px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
    }
    .card-heng-title {
      padding-left: 23px;
      font-size: 27px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
    }
    .card-heng {
      margin: 0 auto;
      margin-top: 30px;
      width: 642px;
      height: 92px;
      background: rgba(38, 38, 38, 0.39);
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      .img {
        width: 44px;
        height: 44px;
        padding-left: 30px;
      }
    }
    .airwallex-title {
      padding-top: 154px;
      text-align: center;
      font-size: 46px;
      font-family: Arial;
      font-weight: bold;
      line-height: 52px;
      color: #ffffff;
    }
    .airwallex-text {
      margin-top: 30px;
      text-align: center;
      font-size: 33px;
      font-family: Arial;
      font-weight: 400;
      line-height: 42px;
      color: #ffffff;
    }
  }
</style>
